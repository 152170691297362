<login-authentication-page (onEnter)="save()">
  <ng-template #header>
    <span class="one-login__greeting--big one-login__greeting--welcome">{{'Login.PasswordReset.Greeting' | translate}} </span>
    <span>{{'Login.PasswordReset.Welcome' | translate}}</span>
  </ng-template>
  <ng-template #content>
    <form #form="ngForm" class="one-login__form" method="post">
      <div class="one-login__form-fields" ngModelGroup="username">
        <ui-input-text [(ngModel)]="username" [require]="true" label="{{'Login.PasswordReset.Username' | translate}}" name="username"></ui-input-text>
      </div>
      <footer class="one-login__footer">
        <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt"
                   label="{{'Login.PasswordReset.Submit' | translate}}" styleClass="one-login__button" type="primary"></ui-button>
      </footer>
    </form>
  </ng-template>
</login-authentication-page>
