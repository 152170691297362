import {FlowElement} from '../database/flow-element';
import {DesignerEntity} from './designer.entity';

export abstract class Workflow extends DesignerEntity {
  public format?: string;
  public entityId?: string;
  public startCondition?: any;
  public cancelCondition?: any;
  public scope?: ScopeEnum;
  public definition?: string | FlowElement[];
  public workflowType?: WorkflowType;
}

export enum WorkflowType {
  BPMN = 'bpmn',
  LowCode = 'lowCode'
}

export enum ScopeEnum {
  Defined = 'defined',
  Inherited = 'inherited',
  Explicit = 'explicit'
}
