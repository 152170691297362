import {ChangeDetectorRef, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KolibriEntity, ListSelectionMode} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {DatatableAction} from '../../entities/datatable-action';
import {OneConfirmService} from '../../service/one-confirm.service';
import {IListComponent} from '../../util/list-component';

@Directive()
export abstract class TableComponent {
  @Input()
  public title: string;
  @Input()
  public icon: string;
  @Input()
  public name: string;
  @Input()
  public totalRecords: number = 0;
  /**
   * helper number for large lazy tables
   * totalRecords will be an estimation
   * this one will be the exact number
   */
  @Input()
  public exactTotalRecords: number;
  @Input()
  public rows: number = 10;
  @Input()
  public globalSearch: boolean = true;
  @Input()
  public selectionMode: string = ListSelectionMode.SINGLE;
  @Input()
  public defaultActions: DatatableAction[] = [DatatableAction.NONE];
  @Input()
  public customActions: MenuItem[];
  @Input()
  public loading: boolean;
  @Input()
  public list: Partial<IListComponent<any>>;
  @Input()
  public values: any[] = [];
  @Output()
  public selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onReset: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onRefresh: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBeforeLoad: EventEmitter<LazyLoadEvent> = new EventEmitter();
  @Output()
  public onAfterLoad: EventEmitter<LazyLoadEvent> = new EventEmitter();
  @ContentChild('rowActions', {static: true})
  public rowActions: TemplateRef<any>;
  @ContentChild('header', {static: true})
  public header: TemplateRef<any>;
  @ContentChild('tools', {static: true})
  public tools: TemplateRef<any>;
  @ContentChild('toolbarButton', {static: true})
  public toolbarButton: TemplateRef<any>;
  @ContentChild('subHeader', {static: true})
  public subHeader: TemplateRef<any>;
  public rowsPerPageOptions: number[] = [5, 10, 25, 50, 75, 100];
  public contextMenu: MenuItem[];
  public contextSelection: any;
  public contextFieldSelection: string;
  private availableContextMenus: { [id: string]: MenuItem[] } = {};

  public constructor(protected translate: TranslateService, protected confirmationService: ConfirmationService, protected messageService: MessageService,
                     protected oneConfirmService: OneConfirmService, protected cdr: ChangeDetectorRef) {
  }

  protected pselection: any;

  @Input()
  public get selection(): any {
    return this.pselection;
  }

  public set selection(value: any) {
    this.contextSelection = value;
    this.pselection = value;
    this.selectionChange.emit(this.selection);
  }

  public get hasViewAction(): boolean {
    return _.some(this.defaultActions, o => o === DatatableAction.VIEW);
  }

  public get hasEditAction(): boolean {
    return _.some(this.defaultActions, o => o === DatatableAction.EDIT);
  }

  public get hasSelectAction(): boolean {
    return _.some(this.defaultActions, o => o === DatatableAction.SELECT);
  }

  public get hasCreateAction(): boolean {
    return _.some(this.defaultActions, o => o === DatatableAction.CREATE);
  }

  public get hasDeleteAction(): boolean {
    return _.some(this.defaultActions, o => o === DatatableAction.DELETE);
  }

  public addRowActions(record: KolibriEntity, customActions: MenuItem[]): void {
    this.availableContextMenus[record.id] = customActions;
    const self = this;
    if (!_.find(this.contextMenu, {label: this.translate.instant('List.Actions.RowActions')})) {
      this.contextMenu.push({
        label: this.translate.instant('List.Actions.RowActions'),
        get items() {
          if (_.some(self.availableContextMenus[self.contextSelection?.id], 'visible')) {
            return self.availableContextMenus[self.contextSelection?.id];
          }
        },
        icon: 'fas fa-fw fa-bolt',
      });

      // destroy object reference to update context menu
      this.contextMenu = [...this.contextMenu];
    }
  }

  public addCustomActions(customActions: MenuItem[]): void {
    for (const customAction of customActions) {
      if (!_.find(this.contextMenu, {label: customAction.label})) {
        const oldCommand = customAction.command;
        customAction.command = () => {
          const data = this.contextSelection;
          oldCommand({data: {record: data, recordOld: data.recordOld, field: this.contextFieldSelection}});
        };
        this.contextMenu.push(customAction);
      }
    }
  }

  public abstract refresh(clearSelection?: boolean): void;

  public abstract count(): void;

  public delete(obj: any, cb: () => void): void {
    this.confirmationService.confirm({
      key: 'deleteConfirm',
      accept: async () => {
        // ignore errors and reload list
        try {
          await this.list.delete(obj);
          this.messageService.add({
            severity: 'success',
            summary: '',
            detail: this.translate.instant('Confirm.DeletedMessage')
          });
        } finally {
          this.refresh();
          this.oneConfirmService.confirmCallback();
          // submit button can stop spinning
          cb();
        }
      },
      reject: () => {
        this.oneConfirmService.confirmCallback();
        cb();
      }
    });
  }

  public async view(event: MouseEvent, data: any): Promise<void> {
    if (this.hasViewAction) {
      await this.list.viewEntry(event, data);
    }
  }

  public viewOnClick($event: MouseEvent, data: any, editing?: boolean): Promise<void> {
    // @ts-ignore
    if (!this.hasSelectAction && !editing && $event.target.tagName !== 'A') {
      return this.view($event, data);
    }
  }

  /**
   * Sets up the different actions of the context menu
   */
  protected setupContextMenu(): void {
    this.contextMenu = [];
    if (this.hasCreateAction) {
      this.contextMenu.push({
        label: this.translate.instant('List.Actions.Create'),
        icon: 'fas fa-fw fa-plus',
        command: () => this.list.create(() => {
        }),
      });
    }
    if (this.hasDeleteAction) {
      this.contextMenu.push({
        label: this.translate.instant('List.Actions.Delete'),
        icon: 'fas fa-fw fa-trash',
        command: () => this.delete(this.contextSelection, () => {
        }),
      });
    }
  }
}
