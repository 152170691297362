import {ScriptParams, ScriptResult} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {Subscription} from 'rxjs';
import * as uuidv4 from 'uuid-random';
import {SessionService} from '../../../portal';
import {KolibriScriptExecutor} from '../../index';

import {ScriptUtil} from '../util/script-util';

/**
 * The renderConditionExecutor provides the possibility to evaluate a renderConditionScript,
 * act when a change in one of the usedFields of the renderConditionScript is detected
 * and provides a getter/setter for the result.
 * Usage: create an instance and call init() on it.
 * @param renderData use this param to ṕut in data that is useful to have tied to the renderConditionExecutor. Can be null/undefined
 */
export class RenderConditionExecutor<T> {
  public id: string = uuidv4();
  public field: string;
  protected subscriptions: { [key: string]: { [key: string]: Subscription } } = {};
  protected destroyed: boolean;
  private rendered: boolean;

  public constructor(public formId: string, protected rootCause: string, protected kolibriScriptExecutor: KolibriScriptExecutor,
                     protected sessionService: SessionService, private renderCondition: string, public renderData: T,
                     protected detectChanges: (visible: boolean) => void = () => {
                     }, protected data: () => ScriptParams = () => ({})) {
  }

  public get visible(): boolean {
    return this.rendered;
  }

  public set visible(shouldBeVisible: boolean) {
    this.rendered = shouldBeVisible;
  }

  protected get canRender(): boolean {
    return true;
  }

  public destroy(): void {
    this.destroyed = true;
    for (const value of Object.values(this.subscriptions)) {
      for (const value1 of Object.values(value)) {
        value1.unsubscribe();
      }
    }
  }

  public init(): this {
    this.evalRenderCondition();
    return this;
  }

  protected evalCondition(script: string): ScriptResult<boolean> {
    return this.kolibriScriptExecutor.evalCondition(this.formId, script, `${this.rootCause}:rendered`, undefined, this.data());
  }

  private evalRenderCondition(): void {
    const scriptResult = this.evalCondition(this.renderCondition || 'true');
    _.maybeAwait(scriptResult.result, (x) => {
      ScriptUtil.subscribeToChanges(this.subscriptions, 'render', scriptResult, () => this.evalRenderCondition(), this.sessionService.viewData[this.formId]);
      // when the component is destroyed ignore any update calls, this is async, in the meantime the component could be destroyed
      if (this.destroyed) {
        return;
      }

      // compares previous value with current. If they differ => detectChanges
      const prevX = this.rendered;
      this.visible = (x && this.canRender) ?? false;
      if (prevX !== this.visible) {
        this.detectChanges(this.visible);
      }
    });
  }
}
