import {MaybePromise} from '@wspsoft/underscore';
import {KolibriEntity} from './kolibri-entity';

export class Attachment extends KolibriEntity {
  public fileName?: string;
  public mimeType?: string;
  public payload?: string;
  public payloadData?: MaybePromise<string>;
  public publik?: boolean;
  public maxSize?: number;
  public entity?: KolibriEntity;
  public entityId?: string;
  public eTag?: string;
}
