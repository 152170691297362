import {ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, TrackByFunction} from '@angular/core';
import {KolibriEntity, ListSelectionMode, TreeNodeType} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {LazyLoadEvent, SortMeta, TableState, TreeDragDropService, TreeNode} from 'primeng/api';
import {TableComponent} from '../table.component';

@Component({
  selector: 'ui-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeDragDropService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent extends TableComponent implements OnInit {
  @Input()
  public treeNodeType: TreeNodeType;
  @Input()
  public sortMeta: SortMeta[];
  @Output()
  public onNodeDrop: EventEmitter<{ source: any; target: any; duplicate: boolean; children: any }> = new EventEmitter();
  @Output()
  public onStateSave: EventEmitter<TableState & { nodes?: TreeNode[] }> = new EventEmitter();
  @Output()
  public onStateRestore: EventEmitter<TableState & { nodes?: TreeNode[] }> = new EventEmitter();
  @ContentChild('rowActionTemplate', {static: true})
  public rowActionTemplate: TemplateRef<any>;
  public tableState: TableState & { nodes?: TreeNode[] };

  public get treeSelectionMode(): string {
    switch (this.selectionMode) {
      case ListSelectionMode.MULTIPLE:
        return 'checkbox';
      case ListSelectionMode.BUTTON:
      case ListSelectionMode.CUSTOM:
        return ListSelectionMode.SINGLE;
      default:
        return this.selectionMode;
    }
  }

  @Input()
  public get treeValues(): TreeNode[] {
    return this.values;
  }

  public set treeValues(values: any[]) {
    // ignore values, that are already a tree node
    if (values.length && !('data' in values[0] && 'leaf' in values[0])) {
      this.values = this.convertToTreeNode(values, this.tableState?.nodes);
      // preload the next level to determine the leaf status
      void this.preLoadData(this.values);
    } else {
      this.values = values;
    }

    // only trigger state save AFTER initialization
    if (this.tableState) {
      this.tableState.nodes = this.getStateNodes(this.values);
      this.onStateSave.emit(this.tableState);
    }
  }

  @Input()
  public trackBy: TrackByFunction<any> = (index) => index;

  public getStateNodes(nodes: TreeNode[] = []): any[] {
    return nodes.map(node => {
      const clone = _.pick(node, ['key', 'expanded', 'label', 'type', 'icon', 'leaf']);
      clone.children = this.getStateNodes(node.children);
      return clone;
    });
  }

  public ngOnInit(): void {
    this.rowsPerPageOptions = _.sortBy(_.uniq([...this.rowsPerPageOptions, this.rows]));
    this.setupContextMenu();

    if (this.customActions) {
      this.addCustomActions(this.customActions);
    }

    // restore prev state
    this.restoreState();
    void this.loadPage(this.tableState, false);
  }

  public refresh(clearSelection?: boolean, externalRefresh: boolean = true): Promise<any> {
    this.onRefresh.emit(externalRefresh);
    return this.loadPage({}, false);
  }

  public count(): Promise<KolibriEntity[]> {
    return this.loadPage({count: true});
  }

  public onNodeExpand(node: TreeNode): Promise<void> {
    // preload the data to the level below the children
    return this.preLoadData(node.children);
  }

  public async loadPage({globalFilter, rows, first, count}: { first?: number; rows?: number; globalFilter?: string; count?: boolean },
                        resetState: boolean = true): Promise<KolibriEntity[]> {
    try {
      this.onBeforeLoad.emit();

      if (!_.isNull(first)) {
        this.tableState.first = first;
      }
      if (!_.isNull(rows)) {
        this.tableState.rows = rows;
      }
      if (!_.isNull(globalFilter)) {
        this.tableState.filters.global = {value: globalFilter, matchMode: 'contains'};
        (this.tableState as LazyLoadEvent).globalFilter = globalFilter;
      }
      if (resetState) {
        delete this.tableState.nodes;
      }

      return await this.list.load({...this.tableState, count} as LazyLoadEvent);
    } finally {
      this.onAfterLoad.emit();
    }
  }

  public hackNodeDrop($event: any): void {
    function updateParent(values: TreeNode[], parent?: TreeNode): void {
      for (const value of values) {
        value.parent = parent;
        const children = value.children || [];

        // update leaf status too
        if (!children.length) {
          delete value.children;
          value.leaf = true;
        }

        updateParent(children, value);
      }
    }

    // make sure the parent property is set for all
    updateParent(this.treeValues);

    const currentParent = $event.dragNode.parent?.data;
    const currentParentNode = this.getNodeById(currentParent?.id);
    const currentRecord = $event.dragNode.data;
    const targetList = currentParentNode?.children || this.values;
    let duplicate = false;
    // check if record is already contained in the target list
    const isSame = currentParent?.id === currentRecord.id;
    if (_.filter(targetList, {key: currentRecord.id}).length > 1 || isSame) {
      // remove duplicate
      _.remove(targetList, $event.dragNode);
      duplicate = true;
    }

    this.treeValues = [...this.values];
    this.cdr.detectChanges();
    this.onNodeDrop.emit({source: currentRecord, target: currentParent, duplicate, children: targetList});
  }

  /**
   * convert data json to tree node object
   */
  public convertToTreeNode(values: any[], stateNodes: TreeNode[] = []): TreeNode[] {
    return values.map(x => ({
      leaf: true,
      ..._.find(stateNodes, {key: x.id}),
      data: x,
      icon: x.icon,
      label: x.representativeString,
      key: x.id,
      type: this.treeNodeType
    } as TreeNode));
  }

  /**
   * get node by id in the whole tree
   */
  private getNodeById(id: string, values: TreeNode[] = this.values): TreeNode {
    for (const value of values) {
      if (value.key === id) {
        return value;
      }
      const nodeById = this.getNodeById(id, value.children || []);
      if (nodeById) {
        return nodeById;
      }
    }
  }

  private restoreState(): void {
    const item = sessionStorage.getItem(this.name);
    this.tableState = item ? JSON.parse(item) : {};
    this.tableState.filters ??= {};
    this.tableState.multiSortMeta ??= this.sortMeta;
    this.tableState.rows ??= this.rows;
    this.tableState.first ??= 0;
    this.onStateRestore.emit(this.tableState);
  }

  /**
   * pre load data for next level to properly set leaf status
   */
  private async preLoadData(children: TreeNode[]): Promise<void> {
    const values: any[][] = await this.list.load(children.map(c => c.data) as any);
    // the array contains all sub entries according to the children index
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      // merge results together in case we restored from state
      child.children = this.convertToTreeNode(values[i], child.children);
      child.leaf = !values[i].length;
    }
    this.treeValues = [...this.values];
    this.cdr.detectChanges();
  }
}
