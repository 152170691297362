<ui-input-wrapper [disable]="disable" [filled]="filled" [helpMessage]="helpMessage" [label]="label" [require]="require">
  <ul #container (click)="toggleOverlay()" [ngClass]="{'one-field-autocomplete-container--disabled one-input--readonly': disable, 'p-autocomplete-multiple-container': multiple,
  'one-field-autocomplete-container': !multiple}" class="one-input">
    <div class="p-hidden-accessible">
      <input #focusInput type="text" role="textbox" readonly [disabled]="disable" (focus)="toggleOverlay()" tabindex="0"/>
    </div>
    <ng-container *ngIf="multiple else singleLabel">
      <ng-container *ngFor="let field of value">
        <ng-container *ngTemplateOutlet="multipleLabelTemplate; context: {$implicit: getValueLabel(field), field}"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!disable">
      <ui-button (clicked)="clear()" *ngIf="value && !multiple" [attr.tabindex]="-1" [disable]="disable" [spinner]="false" class="one-field-autocomplete-clear"
                 icon="fas fa-fw fa-times" type="inline"></ui-button>
      <ui-button [attr.tabindex]="-1" [ngClass]="{'one-field-autocomplete-dropdown--multiple': multiple}" [spinner]="false"
                 class="one-field-autocomplete-dropdown" icon="fas fa-fw fa-chevron-down" type="inline"></ui-button>
    </ng-container>
  </ul>
</ui-input-wrapper>
<ui-field-overlay (onSelect)="onSelect.emit($event); focusInputTrap(!multiple);" (onHide)="focusInputTrap()" (onTabNavigation)="toggleOverlay();
focusInputTrap();" (valueChange)="addValue($event)" [(visible)]="overlayVisible" [additionalFields]="additionalFields" [allowDotWalk]="allowDotWalk"
                  [autoSelect]="!multiple" [conditionBuilderOptions]="conditionBuilderOptions" [containerEl]="container" [entityMeta]="entityMeta" [level]="0"
                  [localized]="localized" [multiple]="multiple" [search]="search" [value]="getPreSelectedValue()"></ui-field-overlay>

<ng-template #singleLabel>
  <ng-container *ngTemplateOutlet="labelTemplate; context: {$implicit: getValueLabel(this.value)}"></ng-container>
</ng-template>

<ng-template #labelTemplate let-labels>
  <div class="one-field-autocomplete-label">
    <span *ngIf="labels[0]" [innerHTML]="labels[0] + (labels[1] ? '.' : '') | safe: 'html'" class="one-field-autocomplete-label--first"></span>
    <span *ngIf="labels[1]" class="one-field-autocomplete-label--last">{{labels[1]}}</span>
  </div>
</ng-template>

<ng-template #multipleLabelTemplate let-field="field" let-labels>
  <li class="p-autocomplete-token">
    <span class="p-autocomplete-token-label">
      <span *ngIf="labels[0]" class="one-field-autocomplete-label--first">{{labels[0]}}</span>
      <span *ngIf="labels[1]" class="one-field-autocomplete-label--last">.{{labels[1]}}</span>
    </span>
    <span (click)="removeValue(field); $event.stopPropagation()" *ngIf="!disable" class="p-autocomplete-token-icon">
      <span class="fas fa-times"></span>
    </span>
  </li>
</ng-template>