import {Injectable} from '@angular/core';
import {AbstractKolibriScriptExecutor} from '@wspsoft/frontend-backend-common';
import {CircularService} from '../../../api';
// noinspection ES6PreferShortImport
import {SessionService} from '../../../portal/app/service/session.service';
import {KolibriScriptContext} from './kolibri-script-context';

@Injectable()
export class KolibriScriptExecutor extends AbstractKolibriScriptExecutor {

  public constructor(context: KolibriScriptContext, sessionService: SessionService, private circularService: CircularService) {
    super(context, sessionService);
    // we have to cheat here due the circular deps
    circularService.scriptExecutor = this;
  }
}
