import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {_, MaybePromise} from '@wspsoft/underscore';
import {SelectItem} from 'primeng/api';
import {CustomInput} from '../custom-input';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class MultiSelect<E> extends CustomInput<E> implements OnInit {
  @Input()
  public options: SelectItem[] = [];
  @Input()
  public placeholder: string;
  @Input()
  public loading: boolean;
  @Input()
  public filterScript: string;
  @Input()
  public field: string;
  @Input()
  public tableId: string;
  @Output()
  public onPanelHide: EventEmitter<any> = new EventEmitter();

  public ngOnInit(): void {
    _.maybeAwait(this.setupOptions(), () => {
    });
  }

  protected setupOptions(): MaybePromise<void> {
  }
}
