import {Injectable} from '@angular/core';
import {Entity, Field, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ModelService, ModelTranslationService} from '../../../../api';
import {Converter} from './converter';

@Injectable()
export class FieldConverterService implements Converter<Field | Field[]> {
  public entityMetaLoader: () => Entity;
  public additionalFields: Field[];
  public localized: boolean;

  public constructor(private modelService: ModelService, private modelTranslationService: ModelTranslationService) {
  }

  public getAsObject(s: string): Field;
  public getAsObject(s: string[]): Field[];
  public getAsObject(s: string | string[]): Field | Field[] {
    if (Array.isArray(s)) {
      if (!s.length) {
        return s as any as Field[];
      }
      return s.map(fieldName => this.getAsObject(fieldName));
    }

    const additionalField = _.find(this.additionalFields, {name: s});
    if (additionalField) {
      return additionalField;
    }

    const entityMeta = this.entityMetaLoader();
    const field = _.clone(this.modelService.getField(entityMeta.id, s));

    if (field) {
      field.label = this.localized ? this.modelTranslationService.translateDotWalkField(entityMeta, s) : s;
      field.path = Utility.getDotWalkPath(s).join('.');
    }
    return field;
  }

  public getAsString(value: Field): string;
  public getAsString(value: Field[]): string[];
  public getAsString(value: Field | Field[]): string | string[] {
    if (Array.isArray(value)) {
      if (!value.length) {
        return value as any as string[];
      }
      return value.map(val => this.getAsString(val));
    }
    if (value) {
      return (value.path ? `${value.path}.` : '') + value.name;
    }
    return null;
  }
}
