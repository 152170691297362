import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, Type} from '@angular/core';
import {ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, Validator} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CodeEditorLanguages, CodeEditorOptions, LargeTextEditorMode} from '@wspsoft/frontend-backend-common';
import {OutputsType} from 'ng-dynamic-component/lib/io';
import {CodeEditorComponent} from '../../../../../standalone-components/app/component/code-editor/code-editor.component';
import {MarkdownEditorComponent} from '../../../../../standalone-components/app/component/markdown-editor/markdown-editor.component';
import {WysiwygEditorComponent} from '../../../../../standalone-components/app/component/wysiwyg-editor/wysiwyg-editor.component';
import {I18nInput} from '../i18n-input';

@Component({
  selector: 'ui-large-textfield',
  templateUrl: './large-textfield.component.html',
  styleUrls: ['./large-textfield.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LargeTextfieldComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LargeTextfieldComponent),
    multi: true,
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LargeTextfieldComponent extends I18nInput<any> implements OnInit, Validator {
  public LargeTextEditorMode: typeof LargeTextEditorMode = LargeTextEditorMode;
  @Input()
  public editorMode: LargeTextEditorMode;
  @Input()
  public rows: number = 5;
  @Input()
  public recordId: string;
  @Input()
  public recordClass: string;
  @Input()
  public codeContext: string;
  @Input()
  public compact: boolean;
  @Input()
  public enableMinimap: boolean = true;
  @Input()
  public indentOnInit: boolean;
  @Input()
  public queryClass: string;
  @Input()
  public language: CodeEditorLanguages;
  @Input()
  public codeEditorOptions: CodeEditorOptions[];
  public codeEditorComponent: Type<CodeEditorComponent>;
  public markdownEditorComponent: Type<MarkdownEditorComponent>;
  public wysiwygEditorComponent: Type<WysiwygEditorComponent>;
  public ngModelOutputs: OutputsType = {
    onChange: event => this.value = event
  };
  private nativeInput: Validator;

  public constructor(translateService: TranslateService, cdr: ChangeDetectorRef,
                     @Optional() @Host() @SkipSelf()
                     public container: ControlContainer) {
    super(translateService, cdr);
  }

  public async ngOnInit(): Promise<void> {
    if (this.editorMode === LargeTextEditorMode.CODE_EDITOR) {
      this.codeEditorComponent = (await import('../../../../../standalone-components/app/component/code-editor/code-editor.component')).CodeEditorComponent;
      this.cdr.detectChanges();
    }
    if (this.editorMode === LargeTextEditorMode.HTML) {
      this.wysiwygEditorComponent = (await import('../../../../../standalone-components/app/component/wysiwyg-editor/wysiwyg-editor.component'))
        .WysiwygEditorComponent;
      this.cdr.detectChanges();
    }
    if (this.editorMode === LargeTextEditorMode.MARKDOWN) {
      this.markdownEditorComponent = (await import('../../../../../standalone-components/app/component/markdown-editor/markdown-editor.component'))
        .MarkdownEditorComponent;
      this.cdr.detectChanges();
    }

    super.ngOnInit();
  }

  public componentCreated($event: ComponentRef<Validator>): void {
    this.nativeInput = $event.instance;
  }

  public validate(control: UntypedFormControl): ValidationErrors | null {
    if (this.nativeInput) {
      return this.nativeInput.validate?.(control);
    }
  }
}
