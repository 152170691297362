import {Directive, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityModel} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {UserService} from '../../../api';
import {PrefQueryEntity} from '../entities/pref-query.entity';
import {MultiTabSynchronizerService} from '../service/multi-tab-synchronizer.service';
import {ShareableEntityService} from '../service/shareableEntity.service';

@Directive()
export abstract class PrefQueryableComponent implements OnInit {
  @Input()
  public id: string;
  @Input()
  public entityMeta: EntityModel;
  @Input()
  public usePrefQuery: boolean = false;
  public defaultPrefQuery: PrefQueryEntity;

  protected constructor(protected router: Router, protected multiTab: MultiTabSynchronizerService, protected shareableEntityService: ShareableEntityService,
                        protected route: ActivatedRoute, protected userService: UserService) {
  }

  private pcurrentPrefQuery: PrefQueryEntity;

  public get currentPrefQuery(): PrefQueryEntity {
    return this.pcurrentPrefQuery;
  }

  public set currentPrefQuery(value: PrefQueryEntity) {
    this.pcurrentPrefQuery = value;
  }

  public ngOnInit(): Promise<void> {
    return this.init();
  }

  /**
   * saves prefQuery in backend
   */
  public savePrefQuery(): void {
    if (this.currentPrefQuery) {
      sessionStorage.setItem(this.id, JSON.stringify(this.currentPrefQuery.criteria));
    }
  }

  protected abstract init(): Promise<void>;

  /**
   * setup pref queries for pref query support
   */
  protected async setupPrefQueries(multiple?: boolean, scopeId: string = this.entityMeta.id): Promise<void> {

    this.defaultPrefQuery = await this.shareableEntityService.getEmptyPrefQuery(this.entityMeta,
      scopeId, multiple);
    if (this.usePrefQuery) {
      // setup pref queries
      const prefQueryId = this.route.snapshot.queryParamMap.get('prefQuery');
      const drilldown = this.route.snapshot.queryParamMap.get('drilldown');
      if (prefQueryId) {
        const prefQuery = await this.userService.getPrefQuery(prefQueryId);
        if (!prefQuery || prefQuery.scope !== scopeId) {
          await this.router.navigate(['error/403'], {replaceUrl: true});
          return;
        }
        this.currentPrefQuery = new PrefQueryEntity(prefQuery);
      } else if (drilldown) {
        const payload = this.multiTab.getData(`drilldownQuery${drilldown}`).payload;
        this.currentPrefQuery = new PrefQueryEntity(payload, true);
      } else {
        this.currentPrefQuery = this.defaultPrefQuery;
      }
    } else {
      this.currentPrefQuery = this.defaultPrefQuery;
    }

    if (multiple && !Array.isArray(this.currentPrefQuery.criteria)) {
      this.currentPrefQuery.criteria = [];
      this.defaultPrefQuery.criteria = [];
    }
  }

  /**
   * get prefQuery from backend and restore it
   */
  protected restorePrefQuery(): void {
    // grab prefQuery from session storage
    const value = JSON.parse(sessionStorage.getItem(this.id) || '{}');
    if (!_.isEmpty(value)) {
      if (this.currentPrefQuery) {
        this.currentPrefQuery.payload = value;
      }

      sessionStorage.setItem(this.id, JSON.stringify(value));
    } else {
      sessionStorage.removeItem(this.id);
    }
  }
}
