import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Host, Input, OnChanges, Optional, SimpleChanges, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Utility} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-custom-list-autocomplete',
  templateUrl: './custom-list-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './custom-list-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomListAutocompleteInputComponent),
    multi: true
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomListAutocompleteInputComponent extends AutoComplete<any> implements OnChanges {
  @Input()
  public values: string[];
  @Input()
  public displayField: string;
  @Input()
  public search: (result: any[], query: string) => MaybePromise<any[]>;


  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get suggestions(): any {
    return super.suggestions;
  }

  public set suggestions(value: any[]) {
    // primeng doesn't support forceSelection with multiple = true
    // so we have to add the query to the suggestions
    if (!this.forceSelection && this.lastQuery) {
      value ??= [];
      value.unshift(this.displayField ? {[this.displayField]: this.lastQuery} : this.lastQuery);
    }
    if (!_.isNullOrEmpty(this.value)) {
      for (const val of this.multiple ? this.value : [this.value]) {
        if (typeof val === 'object' && val[this.displayField] !== undefined) {
          value = _.filter(value, x => x[this.displayField] !== val[this.displayField]);
        } else {
          value = _.filter(value, x => x !== val);
        }
      }
      if (!this.multiple) {
        value.unshift(this.value);
      }
    }
    super.suggestions = value;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.values && !changes.values.firstChange) {
      this.suggestions = [];
    }
  }

  public onComplete($event: any): void {
    (async () => {
      this.lastQuery = $event.query;
      let values = this.values;
      // apply custom filter function
      if (this.search) {
        const result = this.search(values, $event.query);
        values = _.isPromise(result) ? await result : result as string[];
      }

      // sort values either by their displayField or undefined if the values are just plain strings
      this.suggestions = _.sortBy(_.filter(values as any as Record<any, any>[],
          v => Utility.matches(this.getLabel(v), $event.query)),
        this.displayField ? this.displayField : undefined);
      this.cdr.detectChanges();

      if ($event.originalEvent.cb) {
        $event.originalEvent.cb();
      }
    })();
  }

  protected restoreOldValue(): void {
    if (this.values?.map(v => this.getLabel(v)).includes(this.getLabel(this.oldValue))) {
      super.restoreOldValue();
    }
  }

  public getLabel(value: any): string {
    return this.displayField ? value?.[this.displayField] : value;
  }
}
