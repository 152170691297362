import {EventEmitter, Injectable} from '@angular/core';
import {
  AbstractSessionService,
  AccessControlCache,
  Constants,
  CriteriaQuery,
  CriteriaQueryJson,
  KolibriEntity,
  Layout,
  OneLayoutState,
  RelationInfo,
  ScriptDialog,
  ScriptForm,
  ScriptGraph,
  ScriptList,
  ScriptVariable,
  ScriptWizard,
  User
} from '@wspsoft/frontend-backend-common';
import * as uuidv4 from 'uuid-random';
import {CircularService, ModelService} from '../../../api';
import {DashboardEntity} from '../../../dashboard/app/entities/dashboard.entity';
import {UiUtil} from '../../../ui';
import {LayoutFormComponent} from '../components/layout/layout-form/layout-form.component';
import {LayoutFullCalendarComponent} from '../components/layout/layout-full-calendar/layout-full-calendar.component';
import {LayoutGraphComponent} from '../components/layout/layout-graph/layout-graph.component';
import {LayoutListComponent} from '../components/layout/layout-list/layout-list.component';
import {LayoutMapComponent} from '../components/layout/layout-map/layout-map.component';
import {LayoutWebsiteComponent} from '../components/layout/layout-website/layout-website.component';

export class PrevNextData {
  public currentQuery: CriteriaQuery<KolibriEntity>;
  public maxResults: number;
  public listUrl: string;
  public relationInfo?: RelationInfo;

  public get first(): boolean {
    return this.currentQuery.pOffset === 0;
  }

  public get last(): boolean {
    return this.currentQuery.pOffset === this.maxResults - 1;
  }
}

interface DashboardData {
  globalFilter: CriteriaQueryJson;
  queryChange: EventEmitter<void>;
  editMode: boolean;
  editModeChange: EventEmitter<boolean>;
  widgetChange: EventEmitter<void>;
  isDashboardOwner: boolean;
  currentDashboard?: DashboardEntity;
  canEdit: boolean;
}

export interface ViewData {
  id?: string;
  layout?: Layout;
  currentWebsite?: LayoutWebsiteComponent;
  currentForm?: LayoutFormComponent;
  currentList?: LayoutListComponent;
  currentLayoutMap?: LayoutMapComponent;
  currentLayoutGraph?: LayoutGraphComponent;
  currentFullCalendar?: LayoutFullCalendarComponent;
  currentRecord?: KolibriEntity;
  recordParent?: KolibriEntity;
  parentFormId?: string;
  localScriptStorage?: any;
  currentRecordObservables?: { [key: string]: EventEmitter<any> };
  forceSkipLeaveMessage?: boolean;
  layoutState?: OneLayoutState;
  nestedForms?: ScriptForm[];
  nestedLists?: ScriptList[];
  nestedGraphs?: ScriptGraph[];
  variables?: ScriptVariable[];
  scriptDialog?: ScriptDialog;
  scriptWizard?: ScriptWizard;
  scriptGraph?: ScriptGraph;
}


export interface ViewDataHolder {
  [key: string]: ViewData;
}

@Injectable()
export class SessionService extends AbstractSessionService {
  public runningViewDataCleanups: { [id: string]: number } = {};
  public prevNextData: { [key: string]: PrevNextData } = {};
  public dashboardData: DashboardData = {
    editMode: false,
    editModeChange: new EventEmitter<boolean>(),
    widgetChange: new EventEmitter<void>(),
    queryChange: new EventEmitter<void>(),
    isDashboardOwner: false,
    canEdit: false,
    globalFilter: {}
  };
  public primaryFormIdChange: EventEmitter<string> = new EventEmitter();
  public viewData: ViewDataHolder = {};
  public debug: boolean;
  public e2eEnvironment: boolean;
  private readonly aclCache: AccessControlCache;

  public constructor(private circularService: CircularService, private modelService: ModelService) {
    super();

    circularService.sessionService = this;
    this.debug = (window as any).debug;
    this.e2eEnvironment = (window as any).e2eEnvironment;
    this.aclCache = UiUtil.loadPugVariable('aclCache');
    this.currentUser = UiUtil.loadPugVariable('user');
  }

  private pprimaryFormId: string = AbstractSessionService.DEFAULT_FORM_ID;

  public get primaryFormId(): string {
    return this.pprimaryFormId;
  }

  public set primaryFormId(value: string) {
    this.pprimaryFormId = value;
    this.primaryFormIdChange.emit(value);
  }

  public pcurrentUser: User;

  public get currentUser(): User {
    this.calcDashboardOwner();
    return this.pcurrentUser;
  }

  public set currentUser(user: User) {
    this.calcDashboardOwner();
    this.pcurrentUser = user;
    this.circularService.currentUser = user;
    const self = this;
    user.aclData = () => ({
      cache: this.aclCache,
      get jsContext() {
        return self.circularService.scriptExecutor;
      },
      modelService: this.modelService
    });
  }

  public get isNobody(): boolean {
    return this.currentUser.username === 'nobody';
  }

  public get currentRecord(): KolibriEntity {
    return this.viewData[this.primaryFormId]?.currentRecord;
  }

  public get currentDashboard(): DashboardEntity {
    return this.dashboardData.currentDashboard;
  }

  public set currentDashboard(value: DashboardEntity) {
    this.dashboardData.currentDashboard = value;
    this.calcDashboardOwner();
    this.checkRoles();
  }

  public setViewData(formId: string, viewData: ViewData): string {
    if (this.runningViewDataCleanups[formId]) {
      clearTimeout(this.runningViewDataCleanups[formId]);
      delete this.runningViewDataCleanups[formId];
    }
    viewData.id = uuidv4();
    this.viewData[formId] = viewData;
    return viewData.id;
  }

  public cleanupViewData(viewDataId: string, formId: string): void {
    const viewData = this.viewData[formId];
    // if id doesn't match another process has already opened the layout again
    if (viewData?.id === viewDataId) {
      this.runningViewDataCleanups[formId] = setTimeout(() => {
        delete this.viewData[formId];
        delete this.runningViewDataCleanups[formId];
      }, 10000) as any as number;
    }
  }

  public toggleEditMode(): void {
    this.dashboardData.editMode = !this.dashboardData.editMode;
    this.dashboardData.editModeChange.emit(this.dashboardData.editMode);
  }

  public hasGroup(group: string, ignoreAdminRole: boolean = false): boolean {
    return this.currentUser.hasGroup(group, ignoreAdminRole);
  }

  public can(operation: string, entity?: string, level?: string, record: KolibriEntity = this.currentRecord): boolean {
    return this.currentUser.can(operation, entity, level, {record});
  }

  public hasRole(role?: string, ignoreAdminRole: boolean = false): boolean {
    return this.currentUser.hasRole(role, ignoreAdminRole);
  }

  public hasRoles(): boolean {
    return this.currentUser.hasRoles();
  }

  private checkRoles(): void {
    if (this.currentDashboard && this.currentUser) {
      let isAllowed;
      for (const operation of Constants.DEFAULT_OPERATIONS.filter(e => e !== Constants.READ)) {
        isAllowed = this.currentUser.can(operation, 'Dashboard');
        if (isAllowed) {
          break;
        }
      }
      this.dashboardData.canEdit = isAllowed;
    }
  }

  private calcDashboardOwner(): void {
    if (this.currentDashboard && this.pcurrentUser) {
      this.dashboardData.isDashboardOwner = this.currentDashboard.ownerId === this.pcurrentUser.id;
    }
  }
}
