import {ChangeDetectorRef, Directive, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Picklist} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {MenuItem} from 'primeng/api';
import {CustomInput} from './custom-input';

@Directive()
export class I18nInput<T> extends CustomInput<T> implements OnInit {
  public languageOptionButtons: MenuItem[] = [];
  public currentLanguage: string;
  @Input()
  public isI18n: boolean = false;

  public constructor(private translateService: TranslateService, cdr: ChangeDetectorRef) {
    super(cdr);

    this.currentLanguage = this.translateService.currentLang;
  }

  public get value(): any {
    return this.isI18n ? super.value?.[this.currentLanguage] : super.value;
  }

  public set value(value: any) {
    if (this.isI18n && (typeof value === 'string' || value === undefined)) {
      // @ts-ignore
      const newValue = (this.originalValue && 'clone' in this.originalValue) ? this.originalValue.clone() : {...this.originalValue};
      newValue[this.currentLanguage] = value !== '' ? value : undefined;
      // if all languages are deleted, set super.value to undefined -> value in db is now set to null and not an empty array
      super.value = _.some(Object.values(newValue), (v) => v !== undefined) ? newValue : undefined;
    } else {
      super.value = value !== '' ? value : null;
    }
  }

  public ngOnInit(): void {
    if (this.isI18n) {
      const languages = (window as any).languages as Picklist[];
      for (const language of languages) {
        this.languageOptionButtons.push({
          label: language.translations[this.currentLanguage],
          command: (async (languageValue: string) => {
            // cheaty way to allow async i18n data to be loaded
            await super.value?.[languageValue];
            this.currentLanguage = languageValue;
            this.cdr.detectChanges();
          }).bind(this, language.value)
        });
      }
    }
  }
}
