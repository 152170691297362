import {Directive, ElementRef, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {UiUtil} from './ui-util';

/**
 * abstract class for all components that utilize a ng form
 */
@Directive()
export abstract class FormComponent {
  @ViewChild('form', {static: false})
  public form: NgForm;
  protected showSuccessMessage: boolean = true;
  protected entityName: string = 'Form.Record';
  protected recordName: string;

  protected constructor(protected messageService: MessageService, protected translateService: TranslateService,
                        protected elementRef: ElementRef) {
  }

  public async save(cb?: () => void, validate: boolean = true, securedSave: boolean = true): Promise<boolean> {
    const success = await this.checkForm(true, validate, securedSave);

    this.addSuccessMessage(success);

    cb?.();

    return success;
  }

  public async checkForm(save: boolean = true, validate: boolean = true, securedSave: boolean = true): Promise<boolean> {
    // if valid do actual save
    if (!validate || await this.validate()) {
      if (save) {
        return this.doSave(securedSave);
      }
      return true;
    }
    return false;
  }

  public abstract doSave(securedSave: boolean): Promise<boolean>;

  public addSuccessMessage(success: boolean): void {
    if (success && this.showSuccessMessage) {
      this.messageService.add({
        severity: 'success',
        summary: '',
        detail: this.translateService.instant('Form.SaveMessage',
          {entityName: this.translateService.instant(this.entityName), recordName: this.recordName || ''})
      });

      this.markAsUntouched();
    }
  }

  public validate(showMessage: boolean = true, autoFocus: boolean = true): Promise<boolean> {
    this.markAsDirty();
    const promise = new Promise<boolean>(resolve => {
      const subscription = this.form.statusChanges.subscribe(value => {
        if (value !== 'PENDING') {
          this.checkFormIsValid(resolve, showMessage, autoFocus);
          // we are done with validation, lo longer need to subscript
          subscription.unsubscribe();
        }
      });
    });
    this.form.form.updateValueAndValidity();
    return promise;
  }

  /**
   * show a message that validation failed
   */
  protected showValidationFailedMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.translateService.instant('Form.FailedValidationMessage'),
      key: 'growl'
    });
  }

  protected focusFirstEmptyInput(): void {
    // do not refocus!
    // @ts-ignore
    if (!document.activeElement || document.activeElement !== document.body || window.e2eEnvironment) {
      return;
    }

    const activeClasses = document.activeElement.className;
    // only grab focus when not focusing an input already
    if (activeClasses.includes('ng-valid') || activeClasses.includes('ng-invalid')) {
      return;
    }
    const entry = this.findInputFormGroup(this.form, (value: FormGroup) => (value.controls.native || value.controls.ui)?.value === undefined);
    // try to find first invalid input
    (document.evaluate(`.//*[@name="${entry.key}"]//*[@name="native"]`, this.elementRef.nativeElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE,
      null)
      .singleNodeValue as HTMLElement)
      ?.focus();
  }

  /**
   * check if form is valid and show a message if not
   */
  private checkFormIsValid(resolve: (value?: (Promise<boolean> | boolean)) => void, showMessage: boolean = true, autoFocus: boolean = true): void {
    let result = true;
    // if valid do actual save
    if (!this.form.valid) {
      if (autoFocus) {
        this.focusFirstInvalidInput();
      }
      if (showMessage) {
        this.showValidationFailedMessage();
      }
      result = false;
    }
    // resolve this promise and continue execution
    resolve(result);
  }

  /**
   * mark all form elements as dirty
   */
  private markAsDirty(): void {
    UiUtil.markAsDirty(this.form.controls);
  }

  /**
   * mark all form elements as untouched
   */
  private markAsUntouched(): void {
    function markAsUnTouchedRecursive(controls: { [p: string]: AbstractControl }): void {
      for (const control of Object.values(controls)) {
        if (control instanceof FormGroup) {
          markAsUnTouchedRecursive(control.controls);
        } else {
          control.markAsPristine();
          control.markAsUntouched();
          control.updateValueAndValidity();
        }
      }
    }

    markAsUnTouchedRecursive(this.form.controls);
  }

  /**
   * find in the form tree the group that matches the criteria
   */
  private findInputFormGroup(control: FormGroup | NgForm, conditionFn: (control: FormGroup) => boolean): { key: string; value: AbstractControl } {
    for (const [key, value] of Object.entries(control.controls)) {
      if (value instanceof FormGroup) {
        if ((!!value.controls.ui || !!value.controls.native || !!value.controls.portal) && conditionFn(value)) {
          return {key, value};
        }
        const result = this.findInputFormGroup(value, conditionFn);
        if (result) {
          return result;
        }
      }
    }
  }

  private focusFirstInvalidInput(): void {
    // try to find first invalid input
    const entry = this.findInputFormGroup(this.form, (e: FormGroup) => e.invalid);
    if (entry) {
      // try to find first invalid input
      const element = (document.evaluate(`.//*[@name="${entry.key}"]//*[@name="native"]`, this.elementRef.nativeElement, null,
        XPathResult.FIRST_ORDERED_NODE_TYPE, null)
        .singleNodeValue as HTMLElement);


      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
        element.focus();
        const elementsByTagName = element.getElementsByTagName('input');
        if (elementsByTagName?.length) {
          elementsByTagName[0]?.focus();
        }
      }
    }
  }
}
