import {KolibriEntity} from '../kolibri-entity';
import {ImportDefinition} from './import-definition';

export enum ImportMapScriptTrigger {
  onStart = 'onStart',
  onBefore = 'onBefore',
  onAfter = 'onAfter',
  onEnd = 'onEnd'
}

export class ImportMapField extends KolibriEntity {
  public coalesce: boolean;
  public coalesceEmptyFields: boolean;
  public copyEmptyFields: boolean;
  public order: number;
  public sourceField: string;
  public targetField: string;
  public runScript: boolean;
  public script?: string;
  public dateFormat?: string;
  public numberLocale?: string;
}

export class ImportMapScript extends KolibriEntity {
  public name: string;
  public script: string;
  public order: number;
  public when: ImportMapScriptTrigger;
}

export class ImportMap extends KolibriEntity {
  public name: string;
  public targetEntity: string;
  public sourceDefinition: ImportDefinition;
  public importMapScripts?: ImportMapScript[];
  public importMapFields?: ImportMapField[];
  public enforceMandatoryFields: boolean;
  public runScriptTriggers: boolean;
  public useChangeTracking: boolean;
}
