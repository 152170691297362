import {CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {CriteriaOperator, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import * as chroma from 'chroma-js';
import {KanbanItem} from '../../../entities/kanban-item';
import {LayoutViewMode} from '../../../entities/layout-view-mode';
import {DynamicContentService} from '../../../service/dynamic-content.service';
import {RedirectorService} from '../../../service/redirector.service';
import {UiUtil} from '../../../util/ui-util';

export interface KanbanItemState {
  expanded?: boolean;
}

export interface KanbanState {
  globalFilter?: string;
  kanbanColumns: { [title: string]: KanbanItemState };
}

@Component({
  selector: 'ui-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanComponent<T extends KolibriEntity> implements OnInit {
  public LayoutViewMode: typeof LayoutViewMode = LayoutViewMode;
  @Input()
  public title: string;
  @Input()
  public orderField: string;
  @Input()
  public pageSize: number = 20;
  @Input()
  public columns: KanbanItem<T>[];
  @Input()
  public stateKey: string;
  @Input()
  public globalSearch: boolean;
  @Input()
  public viewMode: LayoutViewMode = LayoutViewMode.NESTED;
  @Input()
  public parentViewMode: LayoutViewMode;
  @Output()
  public onStateSave: EventEmitter<KanbanState> = new EventEmitter();
  @Output()
  public onStateRestore: EventEmitter<KanbanState> = new EventEmitter();
  @ContentChild('header', {static: true})
  public header: TemplateRef<T>;
  @ContentChild('subHeader', {static: true})
  public subHeader: TemplateRef<T>;
  @ContentChild('tools', {static: true})
  public tools: TemplateRef<T>;
  @ContentChild('toolbarButton', {static: true})
  public toolbarButton: TemplateRef<T>;
  @ContentChild('itemTemplate', {static: true})
  public itemTemplate: TemplateRef<T>;
  public hovered: string;
  public dragging: boolean = false;
  public state: KanbanState;
  public defaultColor: string;

  public constructor(private cdr: ChangeDetectorRef, private redirectorService: RedirectorService,
                     private dynamicContentService: DynamicContentService) {
  }

  public ngOnInit(): void {
    this.restoreState();
    for (const column of this.columns) {
      column.offset = 0;
      column.limit = this.pageSize;
      this.applyColumnDefaults(column);
      if (column.state.expanded) {
        void this.loadEntries(column);
      }
    }
    this.defaultColor = UiUtil.getColor('otherButtonText')?.trim() ?? '#7777777';
  }

  public getColumnColor(color: string): string {
    return color ?? this.defaultColor;
  }


  public getInvertedColor(color: string): string {
    color = this.getColumnColor(color);
    const value = chroma(color);
    const lum = value.luminance();
    return value.luminance(lum > 0.5 ? lum - 0.5 : lum + 0.5).hex(); // reduce or raise luminance by 50%
  }

  /**
   * load next page for current item
   */
  public async loadEntries(column: KanbanItem<T>, loadMore?: boolean): Promise<void> {
    if (!loadMore) {
      column.value = [];
      column.offset = 0;
    }

    const query = column.query.clone();
    await column.onLoad(query);
    query.addCondition('representativeString', CriteriaOperator.BEGINS_WITH, this.state.globalFilter);
    // @ts-ignore
    const orders = query.orders;
    // @ts-ignore
    query.orders = [];
    query.addOrder(this.orderField).offset(column.offset).limit(column.limit);
    // @ts-ignore
    query.orders.push(...orders);
    const data = await query.getResults();
    column.offset += column.limit;
    column.value.push(...data);
    if (column.autoCollapse && !column.value.length) {
      column.state.expanded = false;
    }
    await column.afterLoad(column.value);
    this.cdr.detectChanges();
  }

  /**
   * moves given item to new position
   */
  public async moveItem(column: KanbanItem<T>, event: CdkDragDrop<T[]>): Promise<void> {
    const entity = event.item.data;
    if (column.sort) {
      entity[this.orderField] = event.currentIndex;
    }
    await column.onMove(entity, column);
    this.dynamicContentService.update(entity.representativeString, entity);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    // when sorting is not allowed, the new position is most probably not correct
    if (!column.sort) {
      await column.refresh();
    }
  }

  /**
   * clears the whole state
   */
  public clearState(): void {
    const storage = window.sessionStorage;

    for (const column of this.columns) {
      column.state = {};
    }

    if (this.stateKey) {
      storage.removeItem(this.stateKey);
    }
  }

  /**
   * restore from browser state
   */
  public restoreState(): void {
    this.state = {
      kanbanColumns: {}
    };
    const storage = window.sessionStorage;
    const stateString = storage.getItem(this.stateKey);

    if (stateString) {
      this.state = JSON.parse(stateString);
      this.onStateRestore.emit(this.state);


      for (const column of this.columns) {
        column.state = this.state.kanbanColumns[column.title] as KanbanItemState || column.state;
        column.autoCollapse = !column.state?.expanded;
      }
    }
  }

  /**
   * save browser state
   */
  public saveState(): void {
    const storage = window.sessionStorage;
    const state: KanbanState = {
      globalFilter: this.state.globalFilter,
      kanbanColumns: {}
    };

    for (const column of this.columns) {
      state.kanbanColumns[column.title] = column.state;
    }

    storage.setItem(this.stateKey, JSON.stringify(state));
    this.state = state;
    this.onStateSave.emit(state);
  }

  /**
   * toggles expansion state of single column
   */
  public async toggle(column: KanbanItem<T>): Promise<void> {
    if (!column.state.expanded) {
      await this.loadEntries(column);
    } else {
      column.value = [];
      column.offset = 0;
      column.limit = this.pageSize;
    }

    column.state.expanded = !column.state.expanded;
    column.autoCollapse = false;
    this.cdr.detectChanges();
    this.saveState();
  }

  /**
   * check if sorting to column is allowed
   */
  public moveCondition(column: KanbanItem<T>): (item: CdkDrag<T>) => boolean {
    return (item: CdkDrag<T>) => column.moveCondition(item.data, column);
  }

  /**
   * check if sorting to position is allowed
   */
  public sortCondition(column: KanbanItem<T>): (index: number, item: CdkDrag<T>) => boolean {
    return (index: number, item: CdkDrag<T>) => column.sortCondition(item.data, index, column);
  }

  /**
   * execute column specific add function and open in page right
   */
  public async add($event: KanbanItem<T>): Promise<boolean> {
    return this.edit(await $event.onAdd($event), $event);
  }

  /**
   * open page right for column element
   */
  public edit(item: T, $event: KanbanItem<T>, clickEvent?: MouseEvent): Promise<boolean> {
    let currentColumn: KanbanItem<T> = $event;
    // @ts-ignore
    return this.redirectorService.redirectToEntity(item, clickEvent ?? UiUtil.getFakePageRightEventForOs(), undefined, undefined, {
      afterSave: async () => {
        await currentColumn.refresh();
        const movedOutOfColumn = _.find<KolibriEntity>(currentColumn.value, {id: item.id});
        if (!movedOutOfColumn) {
          await this.refresh();
          currentColumn = _.find(this.columns, c => !!_.find(c.value, {id: item.id} as any)) ?? $event;
        }
      }
    });
  }

  /**
   * reload all columns
   */
  public refresh(): Promise<void> {
    return _.parallelDo(this.columns, c => c.refresh());
  }

  /**
   * apply and remember global filter
   */
  public async doGlobalSearch($event: string): Promise<void> {
    this.state.globalFilter = $event;
    this.saveState();
    await this.refresh();
  }

  /**
   * set default values if undefined
   */
  private applyColumnDefaults(column: KanbanItem<T>): void {
    column.refresh = () => this.loadEntries(column);
    column.move ??= true;
    column.sort ??= true;
    column.autoCollapse ??= true;
    column.add ??= true;
    column.edit ??= true;
    column.state ??= {
      expanded: true
    };
    column.onAdd ??= () => undefined;
    column.onMove ??= () => {
    };
    column.onLoad ??= () => {
    };
    column.afterLoad ??= () => {
    };
    column.moveCondition ??= () => true;
    column.sortCondition ??= () => true;
  }
}
