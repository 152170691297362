import * as moment from 'moment/moment';
import {Attachment} from './attachment';

import {KolibriEntity} from './kolibri-entity';
import {MessageTemplate} from './message-template';

export enum ActivityLogType {
  externalMessage = 'externalMessage', appMessage = 'appMessage', email = 'email', internalMessage = 'internalMessage', relation = 'relation',
  change = 'change', created = 'created', deleted = 'deleted'
}

export enum ActivityActorObjectType {
  user = 'user', scheduler = 'scheduler', email = 'email', api = 'api', script = 'script'
}

export abstract class ActivityActor {
  public objectType: ActivityActorObjectType;
  public id: string;
  public repImage?: string;
  public displayName: string;
}

export abstract class ActivityObjectTitleContent {
  public header?: string;
  public recipients?: string;
  public cc?: string;
  public attachments?: { id: string; fileName: string; persisted: boolean }[];
  public body: any;
}

export abstract class ActivityObjectTitle {
  [key: string]: ActivityObjectTitleContent;
}

export abstract class ActivityObjectActor {
  public objectType: string;
  public repImage?: string;
  public id: any;
  public displayName: string;
}

export enum ActivityObjectDisplayType {
  internalMessage = 'internalMessage', externalMessage = 'externalMessage', email = 'email',
  appMessage = 'appMessage',
  change = 'change', file = 'file', image = 'image', video = 'video', audio = 'audio', message = 'message'
}

export abstract class ActivityObject {
  public displayType: ActivityObjectDisplayType;
  public content: ActivityObjectTitle;
  public actor: ActivityObjectActor;
}

export abstract class ActivityLogEntryPayload extends KolibriEntity {
  public actor: ActivityActor;
  public objects: ActivityObject[];
}

export class ActivityLogEntry extends KolibriEntity {
  public type: ActivityLogType;
  public entityId: string;
  public recordEntityClass: string;
  public payload: ActivityLogEntryPayload;
}

export abstract class CustomActivityMessage {
  public body: string;
  public header?: string;
  public type: ActivityLogType;
  public recipients?: string[];
  public cc?: string[];
  public messageTemplate?: MessageTemplate;
  public attachments?: Attachment[];
  public send?: boolean;
  public statistics?: CustomActivityStatistics;
}

export abstract class CustomActivityStatistics {
  public createdOn?: string | moment.Moment;
  public lastModified?: string | moment.Moment;
}
